<template>
  <v-app>
    <div>
      <img
        :src="imageUrl"
        style="
          display: block;
          margin: auto;
          margin-bottom: 0px;
          margin-top: 20px; 
          width: auto;
          height: 70px;
        "
      />
      <div
        style="
          color: #1e60ae;
          font-size: 15px;
          top: 0px;
          text-align: center;
          padding-right: 217px;
        "
      >
      </div>
    </div>
    <div class="login-wrapper" v-if="!spinner">
      <!-- <form > -->
      <!-- <img src="https://www.prpseats.com/wp-content/uploads/2017/05/New_PRP_Logo_254x62-1.jpg" style="display: block;     margin: auto; margin-bottom: 10px" /> -->
      <div class="select">
        <v-select
          @change="handleSubmit()"
          v-if="isVisible"
          v-model="select"
          :items="items"
          :item-text="'text'"
          :item-value="'value'"
          outlined
          return-object
          single-line
          label="Select Station"
        ></v-select>
      </div>
      <h2 class="blink_me" style="color: #1e60ae" v-if="badge_id == null">Scan Badge ...</h2>
      <h2 class="blink_me" style="color: #1e60ae" v-else>
        Select Work Center...
      </h2>
      <h2 style="color: red; marign-top: 10px" v-if="login_failed">
        {{ login_failed }}
      </h2>
      <label>
        <input
          id="login-input"
          class="input-login"
          style=""
          v-on:keyup.enter="handleSubmit1()"
          v-model="badge_id"
        />
      </label>
    </div>
    <div v-else class="spinner">
      <v-progress-circular
        indeterminate
        color="rgb(30 96 174)"
      ></v-progress-circular>
    </div>
  </v-app>
</template>
<script>
export default {
  name: "Login",



  data: () => ({
    // badge_id: "adrian.hernandez@bestop.com",
    badge_id: null,
    isVisible: false,
    version: process.env.VUE_APP_VERSION,
    login_failed: null,
    spinner: false,
    select: false,
    items: [],
    imageUrl:"",
  }),
  mounted() {
    if (sessionStorage.prev_workstation_id) {
      this.select = this.items.find(
        (e) => e.value == sessionStorage.prev_workstation_id
      );
      this.focusInput();
    }
    setInterval(() => this.focusInput(), 1000);
    // focusInput
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  methods: {
    focusInput_2() {
      setTimeout(() => {
        var element = document.getElementsByClassName("input-login")[0];
        if (element == null) return;
        element.readOnly = true;
        element.focus();
        setTimeout(function () {
          document.getElementsByClassName("input-login")[0].readOnly = false;
        }, 50);
      }, 0);
    },
    focusInput() {
      if (document.activeElement.tagName === "INPUT") return;
      setTimeout(() => {
        var element = document.getElementsByClassName("input-login")[0];
        if (element == null) return;
        element.readOnly = true;
        element.focus();
        setTimeout(function () {
          document.getElementsByClassName("input-login")[0].readOnly = false;
        }, 50);
      }, 0);
      // element.attr("readonly", "readonly"); // Force keyboard to hide on input field.
      // element.attr("disabled", "true"); // Force keyboard to hide on textarea field.
      // setTimeout(function() {
      //   element.blur(); //actually close the keyboard
      //   // Remove readonly attribute after keyboard is hidden.
      //   element.removeAttr("readonly");
      //   element.removeAttr("disabled");
      // }, 100);
      // this.hideKeyboard(document.getElementById("login-input"));
    },
    hideKeyboard(element) {
      element.attr("readonly", "readonly"); // Force keyboard to hide on input field.
      element.attr("disabled", "true"); // Force keyboard to hide on textarea field.
      setTimeout(function () {
        element.blur(); //actually close the keyboard
        // Remove readonly attribute after keyboard is hidden.
        element.removeAttr("readonly");
        element.removeAttr("disabled");
      }, 100);
    },
    async handleSubmit() {
      if (this.select == null) {
        // alert("Please Select Work Center");
        return;
      } else if (this.badge_id == null) {
        return;
        alert("Please Scan Badge Number");
      }
      // e.preventDefault();
      if (!this.badge_id) return;
      this.spinner = true;
      const token = await this.loginUser({
        badge_id: this.badge_id.trim(),
        workcenter: this.select.value,
      });
      this.spinner = false;
      if (token.success === false || token.authorized === false) {
        this.badge_id = null;
        if (token.msg) {
          this.badge_id = null;
          this.login_failed = token.msg;
        } else {
          this.login_failed =
            "Incorrect Badge ID or Work Center has not been added to your role.";
          this.focusInput();
        }
      } else {
        this.login_failed = null;
        localStorage.setItem("badge_id", this.badge_id.trim());
        localStorage.setItem("login_id", token.login_id);
        localStorage.setItem("operator_name", token.operator_name);
        localStorage.setItem("subsidiary", token.subsidiary);
        localStorage.setItem("workstation", this.select.text);
        localStorage.setItem("workstation_id", this.select.value);
        localStorage.setItem("prev_workstation_id", this.select.value);
        this.$router.push({ name: "Dashboard" });
        // setTimeout(() => {
        //   document.getElementById("search-wo").focus();
        // }, 0);
      }
    },
    async handleSubmit1() {
      if (this.select == null) {
        // alert("Please Select Work Center");
        return;
      } else if (this.badge_id == null) {
        return;
        alert("Please Scan Badge Number");
      }
      // e.preventDefault();
      if (!this.badge_id) return;
      this.spinner = true;
      const token = await this.loginUser({
        badge_id: this.badge_id.trim(),
       
      });
      this.spinner = false;
     
        this.login_failed = null;
     
        localStorage.setItem("subsidiary", token.subsidiary);
        console.log(token.subsidiary);
    if(token.subsidiary=="13"){
      console.log("PRP");
     this.items=[
      {
        text: "Cording",
        value: "1237671",
      },
      {
        text: "Embroidery",
        value: "1442473",
      },
      {
        text: "Cutting",
        value: "1237674",
      },
      {
        text: "Bundling",
        value: "1237668",
      },
      {
        text: "Foaming",
        value: "1237677",
      },
      {
        text: "Weld Picking",
        value: "2300614",
      },
      {
        text: "Framing & Welding",
        value: "1237678",
      },
      {
        text: "Notching (Tube Bending)",
        value: "1237690",
      },
      {
        text: "Inner Liner",
        value: "1237680",
      },
      {
        text: "Lacing",
        value: "1237681",
      },
      {
        text: "Cleaning & Quality",
        value: "1237670",
      },
      {
        text: "Mounting",
        value: "1237682",
      },
      {
        text: "Sewing",
        value: "1237687",
      },
      {
        text: "Sewing Options",
        value: "1442478",
      },
      {
        text: "SpeedStrap Assembly",
        value: "1237689",
      },
      {
        text: "Shipping",
        value: "1237688",
      },
      {
        text: "Receiving",
        value: "1237684",
      },
      {
        text: "Cleaning and Prep",
        value: "1237670",
      },
      {
        text: "Assembly Options",
        value: "1442472",
      },
      {
        text: "Accessory Prep",
        value: "1237660",
      },
      {
        text: "Boxing",
        value: "1237661",
      }
      ];
 console.log(this.items[2]);
    this.isVisible= true;
   
    this.imageUrl="https://www.prpseats.com/wp-content/uploads/2017/05/New_PRP_Logo_254x62-1.jpg";
  }else if(token.subsidiary=="14"){
    this.items=[
      {
        text: "ALUM-Assembly",
        value: "3991218",
      },
      {
        text: "ALUM-Deburr",
        value: "3991219",
      },
      {
        text: "ALUM-Dress Bumper",
        value: "3991220",
      },
      {
        text: "ALUM-Dressing Acc/Roof",
        value: "3991221",
      },
      {
        text: "ALUM-Mig",
        value: "3991222",
      },
      {
        text: "ALUM-Outside Processing-Grinding",
        value: "3991223",
      },
      {
        text: "ALUM-Outside Processing-WJ",
        value: "3991224",
      },
      {
        text: "ALUM-Packing",
        value: "3991225",
      },
      {
        text: "ALUM-Prep",
        value: "3991226",
      },
      {
        text: "ALUM-Q.C.",
        value: "3991227",
      },
      {
        text: "ALUM-Receive-PC",
        value: "3991228",
      },
      {
        text: "ALUM-Receive-Product",
        value: "3991229",
      },
      {
        text: "ALUM-Send-PC",
        value: "3991230",
      },
      {
        text: "ALUM-tack",
        value: "3991231",
      },
      {
        text: "ALUM-Tig",
        value: "3991520",
      },
      {
        text: "STEEL-Mig",
        value: "3991521",
      }
      ];
      console.log(this.items[3]);

      this.isVisible= true;
      
      this.imageUrl="https://7795266-sb1.app.netsuite.com/core/media/media.nl?id=4242047&c=7795266_SB1&h=2gkKpQAwoWYTT0aYfP8CWQhQDPYiKfQa5QPVr99PTDoerO8v";
      }
      else if(token.subsidiary=="18"||token.subsidiary=="17"||token.subsidiary=="16"||token.subsidiary=="15"){
        this.items=[
      {
        text: "MGP - Buffing",
        value: "4372655",
      },
      {
        text: "MGP - Engraving",
        value: "4372652",
      },
      {
        text: "MGP - Form",
        value: "4372653",
      },
      {
        text: "MGP - Outside Processing",
        value: "4372657",
      },
      {
        text: "MGP - Packaging",
        value: "4372659",
      },
      {
        text: "MGP - Paint Fill",
        value: "4372658",
      },
      {
        text: "MGP - Welding",
        value: "4372654",
      }
      ];
      console.log(this.items[3]);

      this.isVisible= true;
     
      this.imageUrl="https://www.calipercovers.com/wp-content/uploads/2022/10/MGP-FLAG-LOGO-FULL-COLOR.png";
      }
        // setTimeout(() => {
        //   document.getElementById("search-wo").focus();
        // }, 0);
      
    },
    loginUser(credentials) {
      debuglog(credentials);
      return fetch(`${process.env.VUE_APP_BACKEND}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      })
        .then((data) => data.json())
        .catch((err) => {
          alert("Server Error. Please Contact IT.");
          location.reload();
        });
    },
  },
};
</script>
<style lang="scss">
.select {
  margin-top: 10px;
  .v-input {
    border-radius: 15px !important;
    width: 269.75px;
    // height: 56px;
    margin: auto !important;
  }
}
.login-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center !important;
  margin-top: 20px;
}

.input-login {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 15px;
  border: 1px solid gray;
  color: black;
  width: 269.75px;
  // height: 56px;
  padding-left: 10px;
  outline: none !important;
  margin-top: 10px;
}

.spinner {
  height: 10vh;
  text-align: center;
}
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
